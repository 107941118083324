<template>
  <div class="pad-agreement-wrap">
    <NavBack/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered">Add new bank</div>
      <div class="main has-background-white">
        <div class="has-text-centered has-text-weight-semibold pad-agreement">Pre-authorized debit agreement</div>
        <div class="hint has-text-centered">To continue, please read and agree to our authorization agreement below:</div>
        <div class="terms-and-conditions-wrapper">
          <div class="terms-and-conditions" @scroll="scrollTerms">
            <div class="navigation-arrow has-text-right is-clickable is-justify-content-center is-align-content-center" v-show="!atTermsBottom">
              <img src="@assets/icons/arrow-down.svg" alt="scroll_arrow" @click="arrowScroll">
            </div>
            <div class="p1 has-text-centered">
              By clicking the “Link Account” button below, you, the Payor, agree to the
              <span class="has-text-primary has-text-weight-semibold is-clickable" @click="openTerms">Terms & Conditions</span>
              and authorize addy Technology Corp. to debit the personal bank account identified previously for the amounts requested by you to fund your addy Wallet.
            </div>
            <div class="p2 has-text-centered">
              You may revoke your authorization at any time in writing, subject to providing notice of 10 days, and have certain recourse rights if any debit does not comply with this agreement.
              To obtain a sample cancellation form, or for more information on your right to cancel a PAD Agreement and recourse rights, contact your financial institution or visit
              <a href="https://www.payments.ca" class="has-text-primary has-text-weight-semibold" target="_blank">www.cdnpay.ca</a>
            </div>
            <div class="p3 has-text-centered">
              For any inquiries to this PAD Agreement, you can contact us at addy Technology Corp., 352-3381 Cambie St, Vancouver, BC V5Z 4R3,  by phone: 1-833-462-9888, or by email:
              <a href="mailto:support@addyinvest.com" class="has-text-primary has-text-weight-semibold">support@addyinvest.com</a>
            </div>
          </div>
        </div>
        <div class="confirm-agreement">
          <b-checkbox
            v-model="agreementChecked"
            type="is-blue"
          >I have read and understand the contents of the Pre-authorized Debit Agreement</b-checkbox>
        </div>
      </div>
      <BottomButtons
        nextButtonText="Link Account"
        :nextDisabled="!agreementChecked || disabledLinkButton"
        :showSteps="false"
        @goNext="linkAccount"
        @goBack="goBack"
      ></BottomButtons>
    </div>
    <div :class="`modal ${showTerms ? 'is-active' : ''}`">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="agreement-name has-text-weight-semibold has-text-white has-text-centered">addy+PAD+Authorization+T&Cs.pdf</div>
        <img src="https://addy-public.s3-us-west-2.amazonaws.com/terms_conditions.jpg" alt="terms_conditions">
      </div>
      <button class="modal-close is-medium" aria-label="close" @click="hideTerms"></button>
    </div>
  </div>
</template>
<script>
import connectBankAccountMixin from '@utils/mixins/connect-bank-account-mixin'
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { initIndexedDB } from '@utils/common-methods/initIndexedDB'
import { connectAccount } from '@api/wallet'
import { decode } from 'js-base64'

export default {
  mixins: [connectBankAccountMixin],
  data() {
    return {
      agreementChecked: false,
      showTerms: false,
      disabledLinkButton: false,
      queryParams: null,
      chequeFile: null,
      atTermsBottom: false,
    }
  },
  components: {
    NavBack,
    MenuAndLogo,
    BottomButtons,
  },
  computed: {
    termsBoxHeight() {
      return document.querySelector('.terms-and-conditions').offsetHeight
    },
    termsScrollHeight() {
      return document.querySelector('.terms-and-conditions').scrollHeight
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.queryParams = JSON.parse(decode(this.$route.query.params))
      if (this.queryParams.type === 'manual') this.getCheque()
    },
    arrowScroll() {
      const termsElement = document.querySelector('.terms-and-conditions')
      termsElement.scrollTop = termsElement.scrollHeight
    },
    getCheque() {
      const objectStore = 'voidCheque'
      let fileType
      initIndexedDB('bankAccount', objectStore, (db) => {
        const transaction = db.transaction([objectStore])
        const request = transaction.objectStore(objectStore).get('fileType')
        request.onsuccess = () => {
          fileType = request.result
          const request2 = transaction.objectStore(objectStore).get('file')
          request2.onsuccess = () => {
            const arrayBuffer = request2.result
            const blob = new Blob([arrayBuffer], { type: fileType })
            const fileName = `void-cheque.${fileType.split('/')[1]}`
            this.chequeFile = new File([blob], fileName, { type: fileType })
          }
        }
      })
    },
    scrollTerms() {
      const scrollTop = document.querySelector('.terms-and-conditions').scrollTop
      if (this.termsBoxHeight + scrollTop >= this.termsScrollHeight) {
        this.atTermsBottom = true
        return
      }
      this.atTermsBottom = false
    },
    goBack() {
      this.$router.go(-1)
    },
    linkAccount() {
      this.disabledLinkButton = true
      const { type, accountName, transitNumber, routingNumber, accountNumber, plaidAccountId, isPersonalAccount } = this.queryParams

      const basePayload = {
        account_name: accountName,
        transit_number: transitNumber,
        routing_number: routingNumber,
        account_number: accountNumber,
        is_personal_account: isPersonalAccount,
      }

      const params = this.handlePayloadForLinkAccount({ type, basePayload, plaidAccountId, chequeFile: this.chequeFile })
      connectAccount(params).then((data) => {
        if (!data.success) return

        this.cleanLocalData()
        this.disabledLinkButton = false
        this.$router.push({
          path: '/wallet/accounts/done',
          query: {
            type,
          },
        })
      })
    },
    cleanLocalData() {
      localStorage.removeItem('bankAccount')

      const objectStore = 'voidCheque'
      initIndexedDB('bankAccount', objectStore, (db) => {
        const transaction = db.transaction([objectStore], 'readwrite')
        transaction.objectStore(objectStore).clear()
      })
    },
    openTerms() {
      this.showTerms = true
    },
    hideTerms() {
      this.showTerms = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.pad-agreement-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding-bottom: 80px;
  min-height: 100vh;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      border-radius: 12px;
      box-shadow: 0px 20px 40px rgba(215, 145, 51, 0.15);
      margin-bottom: 30px;
      padding: 0 14px;
      .pad-agreement {
        font-size: 16px;
        padding-top: 24px;
      }
      .hint {
        padding: 8px 14px 16px;
        font-size: 16px;
        line-height: 19px;
      }
      .terms-and-conditions-wrapper {
        position: relative;
        .terms-and-conditions {
          background: #F4F4F4;
          height: 230px;
          border-radius: 6px;
          overflow: auto;
          padding: 24px;
          scroll-behavior: smooth;
          >.has-text-centered {
            font-size: 14px;
            line-height: 17px;
            &.p2 {
              padding: 12px 0;
            }
          }
          .navigation-arrow {
            display: flex;
            z-index: 100;
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: 5px;
            right: 12px;
            opacity: 0.3;
            &:hover {
              opacity: 1;
            }
            img {
              width: 20px;
            }
          }
          .is-clickable:hover {
            filter: brightness(85%);
          }
        }
      }
      .confirm-agreement {
        .b-checkbox.checkbox {
          padding: 20px 24px;
          margin-left: 10px;
          margin-right: unset;
          ::v-deep .check {
            width: 24px!important;
            height: 24px!important;
            border-color: #4A26AA!important;
          }
          ::v-deep .control-label {
            font-size: 16px;
            line-height: 19px;
            padding-left: 15px;
          }
        }
      }
    }
  }
  .modal {
    .modal-content {
      height: 520px;
      max-height: unset;
      .agreement-name {
        height: 28px;
        font-size: 18px;
      }
      > img {
        width: 100%;
        height: calc(100% - 31px);
      }
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .pad-agreement-wrap {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
    .modal .modal-content {
      height: 100vh;
      >img {
        object-fit: contain;
      }
    }
  }
}
</style>
